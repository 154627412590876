<template>
  <div class="settings_component">
    <!--<div id="referralModal" class="modal fade normal_modal referralModal" role="dialog" tabindex="-1">
        <div class="modal-dialog">

            <div class="modal-body">
                <button type="button" class="close" data-dismiss="modal">
                    <img src="/img/cancel.png" alt="">
                </button>

                <div class="referral_box">
                    <img src="/img/pattern.svg" alt="">
                    <p>
                        4343 Add  more referral to unlock this feature
                    </p>
                </div>
                <div class="shorten_form text-center"  style="padding-bottom:30px;">
                    <p class="link shorten_sec">
                        <a v-clipboard:copy="referralLink()"
                           v-clipboard:success="onCopy"
                           v-clipboard:error="onError"
                           class="share_link" href="javascript:;"> {{referralLink()}}</a>
                        <input type="button"  v-clipboard:copy="referralLink()"
                               v-clipboard:success="onCopy"
                               v-clipboard:error="onError" value="Copy to Clipboard" class="btn&#45;&#45;cta">
                    </p>
                </div>

            </div>
        </div>
    </div>-->
    <div class="container ">
      <div class="  settings_component_inner setting_page d-flex align-items-start">

        <div class="setting-sidebar">
          <ul>
            <li>
              <router-link :to="{name:'profile'}">
                <i class="far fa-user"></i>
                <span>Profile</span>
              </router-link>
            </li>

            <li
              v-if="getSubscription.fastspring_name && getProfile.policy.can_view_billing">
              <router-link :to="{name:'billing'}">
                <i class="far fa-money"></i>
                <span>Billing</span>
              </router-link>
            </li>

            <li v-if="getProfile.policy.can_view_team_members">
              <router-link :to="{name:'team'}">
                <i class="far fa-users"></i>
                <span>Team</span>
              </router-link>
            </li>

            <li v-if="false && isAllowed">
              <router-link :to="{name:'clients'}">
                <i class="far fa-users"></i>
                <span>Clients</span>
              </router-link>
            </li>

            <li v-if="getProfile.policy.can_view_integrations">
              <router-link :to="{name:'integrations'}">
                <i class="far fa-cogs"></i>
                <span>Integrations</span>
              </router-link>
            </li>

            <li class="position-relative">
              <!--            <li v-if="getProfile.policy.can_view_deep_links && !getSiteDetails.isWhiteLabel" class="position-relative">-->
              <span class="badge settings">New!</span>
              <router-link :class="{'router-link-exact-active active_tab' : getActiveTab()}"
                           :to="{name:'social-media'}">
                <i class="far fa-external-link-square"></i>
                <span>Deep Links</span>
              </router-link>
            </li>

            <li v-if="getProfile.policy.can_view_domains">
              <router-link :to="{name:'custom_domains'}">
                <i class="far fa-globe"></i>
                <span>Custom Domains</span>
              </router-link>
            </li>

            <div class="bg-white border rounded-lg">
              <li class="submenu_heading" v-if="getProfile.policy.can_view_miscellaneous">
              <p :class="{'active_tab' : getActiveTab('misc_settings')}" class="border-0">
                <i class="far fa-cog"></i>
                <span>Misc. Settings</span>
              </p>
            </li>
              <div class="pl-2">

              <li v-if="getProfile.policy.can_view_pixels">
                <router-link class="border-0" :to="{name:'pixels'}">
                  <i class="far fa-link"></i>
                  <span>Retargeting Pixels</span>
                </router-link>
              </li>
              <li v-if="getProfile.policy.can_view_widgets">
                <router-link class="border-0" :to="{name:'custom_code'}">
                  <i class="far fa-code"></i>
                  <span>Custom Widgets</span>
                </router-link>
              </li>
              <li v-if="getProfile.policy.can_view_utm">
                <router-link class="border-0" :to="{name:'utm'}">
                  <i class="  fab fa-superpowers"></i>
                  <span>UTM Tracking</span>
                </router-link>
              </li>

              <li v-if="getProfile.policy.can_view_tags">
                <router-link class="border-0" :to="{name:'tags'}">
                  <i class="far fa-tag"></i>
                  <span>Tags</span>
                </router-link>
              </li>

              <li
                v-if="[ROLES.super_admin,ROLES.admin].includes(getProfile.role)">
                <router-link class="border-0" :to="{name: 'blacklist_ip'}">
                  <i class="far fa-ban"></i>
                  <span>Blacklist IPs</span>
                </router-link>
              </li>

              <li v-if="getProfile.policy.can_view_miscellaneous">
                <router-link class="border-0" :to="{name: 'miscellaneous'}">
                  <i class="far fa-cog"></i>
                  <span>Miscellaneous</span>
                </router-link>
              </li>

            </div>
            </div>

            <li v-if="getSubscriptionLimits('whitelabel') && isSuperAdmin()">
              <router-link :to="{name: 'white-label'}">
                <i class="far fa-window-restore"></i>
                <span>Whitelabel</span>
              </router-link>
            </li>

            <li
              v-if="getProfile.policy.can_view_developer && isFeatureAllowedInPlan('api_access')">
              <router-link :to="{name:'apps'}">
                <i class="fab fa-deviantart"></i>
                <span>Developer Apps</span>
              </router-link>
            </li>

            <li v-if="isSuperAdmin()">
              <router-link :to="{name: 'refer&earn'}">
                <i class="fa fa-gift"></i>
                <span>Refer & Earn</span>
              </router-link>
            </li>


          </ul>
        </div>

        <transition name="slide">
          <router-view></router-view>
        </transition>

      </div>
    </div>
  </div>
</template>

<script>
import Headerbar from '@/components/Header.vue'
import {mapGetters} from 'vuex'
import {ROLES} from '@/common/constants'
import {allowedMembers} from "@/common/attributes";

export default ({
  'components': {
    Headerbar
  },
  computed: {
    ...mapGetters(['getProfile', 'getSubscription']),
    isAllowed () {
      return this.allowedMembers.includes(this.getProfile.email)
    },
  },
  data () {
    return {
      allowedMembers: allowedMembers,
      ROLES: ROLES
    }
  },
  methods: {
    getActiveTab (type = 'deep_links') {
      if(type == 'deep_links') {
        return (['music', 'video', 'e-commerce', 'social-media', 'productivity']).includes(this.$route.name)
      }

      return (['pixels', 'custom_code', 'utm', 'tags', 'blacklist_ip', 'miscellaneous']).includes(this.$route.name)
    }
  }
})
</script>

<style scoped lang="less">
@-moz-document url-prefix() {
  .settings_component .setting-sidebar {
    min-width: 230px;
  }
}
</style>
